export const environment = {
  appVersion: require('../../package.json').version,
  type: 'development',
  production: false,
  DAM_SERVICE: {
    tenant: 'tgc__zm43qqd4bf4tqq5gdxuc',
    url: 'https://dam.vilua.de/v1/',
  },
  BASE_MEDIA_URL: 'https://media.vilua.de/tk/ecoach/',
  DEV_SETTINGS: {
    objectiveDeletionEnabled: false,
    participantCreationEnabled: false,
  },
  MS_AZURE_SETTINGS: {
    apiScopes: ['api://f4e5a338-fd8a-4b48-8395-64e276d511a3/access_as_user'],
    authority: 'https://login.microsoftonline.com/777634b8-6549-48dd-89f9-71c677fea243',
    clientId: '62c96186-387e-424c-b695-685d2fd9f938',
    postLogoutRedirectUri: 'https://tk-tegeco-dev.tk.significo.dev/login',
    redirectUri: 'https://tk-tegeco-dev.tk.significo.dev/authentication',
  },
};
